import { q, qq } from 'bobjoll/ts/library/dom';

const sliderHome = qq('.slider-tools .item');

sliderHome.forEach(element => {
    element.addEventListener(
        'click',
        function(e: Event) {
            sliderHome.forEach(slider => {
                slider.classList.remove('active');
            });

            let sliderActive = e.currentTarget as HTMLElement;
            sliderActive.classList.add('active');

            let order = sliderActive.dataset.order || '0';
            let sliderImages = qq('.slider-box--images picture img');
            sliderImages.forEach(sliderImage => {
                sliderImage.classList.remove('active');
            });

            let sliderImageActive = sliderImages[parseInt(order)];
            sliderImageActive.classList.add('active');

            let mobileSlider = qq('.slider-box--items-mobile .item-text');
            mobileSlider.forEach(sliderMobileItem => {
                sliderMobileItem.classList.remove('active');
            });

            let sliderMobileActive = mobileSlider[parseInt(order)];
            sliderMobileActive.classList.add('active');
        },
        { passive: true },
    );
});

const sliderOverflowNext = q('.mobile-gradient-next');
const sliderOverflowPrev = q('.mobile-gradient-prev');
const sliderOverflow = q('.slider-box--items');

if (sliderOverflow) {
    if (sliderOverflow.scrollWidth > sliderOverflow.clientWidth) {
        sliderOverflowNext?.classList.add('active');
        sliderOverflow.classList.add('active');
    }

    sliderOverflow.onscroll = function() {
        let finalPos = sliderOverflow.scrollWidth - sliderOverflow.clientWidth;
        if (sliderOverflow.scrollLeft >= finalPos) {
            sliderOverflowNext?.classList.remove('active');
            sliderOverflowPrev?.classList.add('active');
        }

        if (sliderOverflow.scrollLeft == 0) {
            sliderOverflowNext?.classList.add('active');
            sliderOverflowPrev?.classList.remove('active');
        }
    };
}
